import * as React from 'react';

import Layout from './layout';
import ArticlesList from './articles-list';
import Pagination from './pagination';
import { Seo } from './seo';
import { textContainer } from './text-container.module.css';
import { title, pageNumber } from './articles-archive.module.css';

const ArticlesArchive = ({
  type,
  currentPage,
  numPages,
  description,
  articles,
  stub,
}) => {
  const pageTitle = `${type ? type + ' ' : ''}Articles — Page ${currentPage}`;
  return (
    <Layout pageTitle={pageTitle}>
      <Seo pageTitle={pageTitle} description={description} />
      <div className={textContainer}>
        <h1 className={`${title} fancy`}>{type ? type + ' ' : ''}Articles</h1>
        <div className={pageNumber}>Page {currentPage}</div>
        <ArticlesList articles={articles} />
        <Pagination
          numPages={numPages}
          currentPage={currentPage}
          pagingPath={stub}
        />
      </div>
    </Layout>
  );
};
export default ArticlesArchive;
