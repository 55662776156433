import * as React from 'react';
import { Link } from 'gatsby';

import { pagination } from './pagination.module.css';

const Pagination = ({ numPages, currentPage, pagingPath }) => {
  const isFirstPage = currentPage === 1;
  const isSecondPage = currentPage === 2;
  const isLastPage = currentPage === numPages;
  const firstPagePath = `/${pagingPath}`;
  const previousPagePath = `/${pagingPath}/${
    isSecondPage ? '' : currentPage - 1
  }`;
  const nextPagePath = `/${pagingPath}/${
    isLastPage ? currentPage : currentPage + 1
  }`;
  const lastPagePath = `/${pagingPath}/${numPages}`;
  return (
    <div className={`${pagination} grid`}>
      <Link to={firstPagePath} disabled={isFirstPage}>
        &#8810;
      </Link>
      <Link to={previousPagePath} disabled={isFirstPage}>
        &lt;
      </Link>
      <span>{currentPage}</span>
      <Link to={nextPagePath} disabled={isLastPage}>
        &gt;
      </Link>
      <Link to={lastPagePath} disabled={isLastPage}>
        &#8811;
      </Link>
    </div>
  );
};

export default Pagination;
