import * as React from 'react';
import { graphql } from 'gatsby';

import ArticlesArchive from './articles-archive';

const AllArticlesArchive = ({ data, pageContext }) => {
  const { numPages, currentPage } = pageContext;
  return (
    <ArticlesArchive
      currentPage={currentPage}
      numPages={numPages}
      description={`Articles that will help you leave your job to become a web developer. I'll teach you the technical parts and the soft skills you need to make it work.`}
      articles={data.allMdx.edges}
      stub='articles'
    />
  );
};
export default AllArticlesArchive;

export const query = graphql`
  query AllArticles($limit: Int!, $skip: Int!) {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt
          slug
          frontmatter {
            coverImage {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
              publicURL
            }
            title
            tags
            date
            description
          }
        }
      }
    }
  }
`;
